<template>
  <div class="zhengshuyangshi">
    <heade></heade>
    <div class="cen_wrap" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="cen_box nice-scroll" v-html="articleh.content"></div>
      <div class="link_box">
        <router-link to="/train/recruitStudents">招生简章</router-link>
        <router-link to="/train/baomingxuzhi">报名须知</router-link>
        <router-link to="/PersonalCenter">学习入口</router-link>
        <router-link class="addclass" to="/train/zhengshuyangshi">证书样式</router-link>
        <router-link to="/train/talentPool">人 才 库</router-link>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/foot.css";
import $ from "jquery";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { getArticle } from "@api/public";
import { scroll } from "@utils/scroll";
export default {
  components: {
    heade,
    foot,
  },
  data() {
    return {
      articleh: {},
      queryWhere: {},
      backgroud: "",
    };
  },
  mounted: function () {
    let that = this;
    getArticle(that.queryWhere).then(function (res) {
      that.$set(that, "articleh", res.data);
    });
    window.onresize = function () {
      return (function () {
        that.$router.go(0);
      })();
    };
    scroll($(".nice-scroll"));
  },
  created: function () {
    this.queryWhere.id = this.$route.meta.id;
    document.title = this.$route.meta.title;
    this.backgroud = this.$route.meta.background;
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style>
.zhengshuyangshi .cen_wrap {
  width: 100%;
  height: 1808px;
  background-size: cover;
  background-position: center 0;
}

.zhengshuyangshi .cen_box {
  width: 1330px;
  height: 856px;
  position: relative;
  top: 390px;
  overflow-y: scroll;
  padding: 50px 100px;
  box-sizing: border-box;
  margin: 0 auto;
}

.zhengshuyangshi .link_box {
  width: 480px;
  height: 250px;
  margin: 0 auto;
  position: relative;
  top: 520px;
  display: flex;
  align-items: center;
}

.cen_box::-webkit-scrollbar {
  display: none;
}

.link_box a {
  font-size: 18px;
  font-family: "楷体";
  font-weight: 600;
  writing-mode: vertical-lr;
  color: #006649;
  padding-right: 44px;
  padding-left: 20px;
}

.link_box>.addclass {
  color: #900c0c;
}
</style>